var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import DailySummary from "../../owner/eventGiveaway/components/DailySummary.vue";
const MINUTE_ONLY = /^\d\d:\d\d$/;
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
        this.timeMode = false;
    }
    get title() {
        return (this.id ? "編輯" : "新增") + "投Ｏ送Ｘ方案";
    }
    mounted() {
        if (this.id) {
            this.load();
        }
        else {
            this.data = {
                CodeName: "buyandgetfree",
                IsActive: true,
                Settings: {
                    TimeLimit: 0,
                },
            };
        }
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            this.data = yield post("/api/Service/GetBGF", { Id: this.id });
            this.timeMode = this.data.Settings.StartHour != this.data.Settings.EndHour;
        });
    }
    submit() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data)
                return;
            // 這段修正是 C# 正確轉換 TimeOnly 格式所必須的
            const settings = this.data.Settings;
            if (!this.timeMode) {
                settings.StartHour = settings.EndHour = "00:00:00";
            }
            if (settings.StartHour.match(MINUTE_ONLY))
                settings.StartHour += ":00";
            if (settings.EndHour.match(MINUTE_ONLY))
                settings.EndHour += ":00";
            yield post("/api/Service/EditBGF", Object.assign({}, this.data));
            this.$router.back();
        });
    }
};
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Prop()
], default_1.prototype, "id", void 0);
default_1 = __decorate([
    Component({
        components: { DailySummary },
    })
], default_1);
export default default_1;
