var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
let default_1 = class default_1 extends Vue {
    info() {
        this.alert(`<img src="/img/service_point.png" style="height:3rem" class="mb-2"><h5>LeaPA 服務點</h5><div>用於扣抵特店服務中，需付費的服務。</div>`);
    }
    add() {
        this.alert(`如須加值服務點，請洽 <a href="https://lin.ee/kUcmeAE" target="_blank">LeaPA 客服</a>。`);
    }
};
__decorate([
    Prop()
], default_1.prototype, "points", void 0);
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
