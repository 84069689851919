
	import { Component, Inject } from "vue-property-decorator";

	import Base from "../Base";
	import DailySummary from "../owner/eventGiveaway/components/DailySummary.vue";
	import OutletList from "../merchant/OutletList.vue";
	import ServicePoints from "./components/ServicePoints.vue";

	import type { ServiceIndexModel } from "@/models/Service";
	import type { PromotionSummary } from "@/models/Owner";

	@Component({ components: { OutletList, DailySummary, ServicePoints } })
	export default class extends Base {
		@Inject() public alert!: AlertMethod;

		protected data: ServiceIndexModel | null = null;

		mounted(): void {
			this.load();
		}

		private async load() {
			this.data = null;
			this.data = await post<ServiceIndexModel>("/api/Service");
		}

		protected getSummary(code: string): PromotionSummary {
			return this.data!.Promotions.find(p => p.CodeName == code)!;
		}

		protected bgfInfo(): void {
			this.alert(
				"<div class='text-left'><ol><li>每台設備日費 2 點</li><li>計算區間：當日04:00～隔日03:59</li>" +
					"<li>收費方式（用前先收）：<br>設備當日首次開通即扣日費，如金額不足會提示無法啟用。<br>" +
					"每日00:00系統會統計開啟中的設備數，扣除設備日費，如金額不足即立即停止使用。在營收報表可以看到交易紀錄顯示加碼送點。</li>" +
					"<li>在leapa板螢幕上顯示再投五次就送一次。</li><li>投幾送包含投幣/掃碼支付/卡機支付/遠端投幣。</li></ol></div>",
				"付費說明", "warning"
			);
		}
	}
