
	import { Component, Inject, Vue } from "vue-property-decorator";

	import { TerminalPromotionStatus } from "@/models/Owner";
	import DailySummary from "../../owner/eventGiveaway/components/DailySummary.vue";
	import type { PromotionModel } from "@/models/Owner";

	import type { ServicePromotionIndexModel, BuyAndGetFreeModel, ServicePromotionIndexMerchantKioskModel } from "@/models/Service";

	type IndexModel = ServicePromotionIndexModel<BuyAndGetFreeModel>;

	const letter = ["A", "B", "C"];
	const TIMEOUT = 60000; // 這邊要長一點，否則無法正確呈現錯誤訊息
	const REMOVE_TIMEOUT = 60000;

	@Component({
		components: { DailySummary },
	})
	export default class extends Vue {
		@Inject() public readonly alert!: AlertMethod;
		@Inject() public readonly confirm!: ConfirmMethod;

		protected data: IndexModel | null = null;

		mounted(): void {
			this.load();
		}

		private async load() {
			this.data = null;
			const data = await post<IndexModel>("/api/Service/PromotionIndex", { Id: "buyandgetfree" });

			// 前端重組資料
			for(const k of data.Kiosks) {
				const id = k.Kiosk.Id;
				if(data.TodayDeduced.includes(id)) k.TodayDeduced = true;
				const terminal = data.Terminals.find(t => t.KioskId == id);
				if(terminal && terminal.Status != TerminalPromotionStatus.Deactivated) k.PromotionId = terminal.PromotionId;
				else k.PromotionId = undefined;
				k.Processing = false;
			}
			this.data = data;
		}

		protected info(): void {
			this.alert(
				"<div class='text-left'><ol><li>設備「開通服務」即可開始設定方案</li><li>設備有設定方案才需計費</li></ol></div>",
				"設備方案",
				"warning"
			);
		}

		protected edit(p: PromotionModel<BuyAndGetFreeModel>): void {
			this.$router.push("/service/bgf/edit/" + p.Id);
		}

		protected async remove(p: PromotionModel<BuyAndGetFreeModel>): Promise<void> {
			if(!this.data) return;
			if(!await this.confirm("原來套用該方案的設備狀態將改為「不使用」", "確定要刪除方案？", "warning")) return;
			try {
				await post<boolean>("/api/Service/RemovePromotion", { Id: p.Id }, REMOVE_TIMEOUT);
				this.data.Promotions.splice(this.data.Promotions.indexOf(p), 1);
				for(const k of this.data.Kiosks) {
					if(k.PromotionId == p.Id) k.PromotionId = undefined;
				}
			} catch(e) {
				if(e instanceof Error) await this.alert(e.message, undefined, "error");
			}
		}

		protected async setPromotion(k: ServicePromotionIndexMerchantKioskModel, id: string | undefined): Promise<void> {
			// 先切換 id 以反應在 UI 上面
			if(k.PromotionId === id) return;
			const oldId = k.PromotionId;
			k.PromotionId = id;


			try {
				if(!k.TodayDeduced && id !== undefined) {
					if(!await this.confirm('本台今日首次設定方案，將扣日費 <img src="/img/service_point.png" class="img-pt" /> 2 點', "溫馨提醒")) throw true;
				}

				k.Processing = true;
				await post<boolean>("/api/Service/SetPromotion", { Id: k.Id, Name: "buyandgetfree", PromotionId: id }, TIMEOUT);
				k.Processing = false;

				if(id !== undefined) {
					k.TodayDeduced = true;
					await this.alert(k.TodayDeduced ? "" : '已扣日費 <img src="/img/service_point.png" class="img-pt" /> 2 點', "已開通方案", "success");
				} else {
					await this.alert("此設備「明日」開始停止收費", "已關閉方案", "success");
				}

			} catch(e) {
				if(e instanceof Error) await this.alert(e.message, "方案變更失敗", "error");
				k.PromotionId = oldId;
				k.Processing = false;
			}
		}

		protected getLetter(i: number): string {
			return letter[i];
		}
	}
