export var EventType;
(function (EventType) {
    EventType[EventType["Charge"] = 10] = "Charge";
    EventType[EventType["Purchase"] = 11] = "Purchase";
    EventType[EventType["TransferIn"] = 12] = "TransferIn";
    EventType[EventType["ConcreteIn"] = 13] = "ConcreteIn";
    EventType[EventType["VirtualIn"] = 14] = "VirtualIn";
    EventType[EventType["WriteOffCancel"] = 15] = "WriteOffCancel";
    EventType[EventType["WriteOff"] = 20] = "WriteOff";
    EventType[EventType["Refund"] = 21] = "Refund";
    EventType[EventType["TransferOut"] = 22] = "TransferOut";
    EventType[EventType["ConcreteOut"] = 23] = "ConcreteOut";
    EventType[EventType["VirtualOut"] = 24] = "VirtualOut";
})(EventType || (EventType = {}));
